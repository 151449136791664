import React from 'react'

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      if (row.values[id] !== "None")
        options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        step="any"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseFloat(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        step="any"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseFloat(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

function TextFilter({
  column: { filterValue, setFilter, id },
}) {
  // Function to handle text input filtering
  const handleFilterInput = (e) => {
    const filterText = e.target.value;
    setFilter(filterText ? filterText : undefined);
  };

  // Render a text input for filtering
  return (
    <>
      <div>
        <input
          type="text"
          placeholder="Type here to filter..."
          value={filterValue || ''}
          onChange={handleFilterInput}
        />
      </div>
    </>
  );
}

export function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed || sd) {
    return rows.filter((r) => {
      // format data
      var dateAndHour = r.values[id].split("T");
      var [year, month, day] = dateAndHour[0].split("-");
      var date = [month, day, year].join("/");
      var hour = dateAndHour[1];
      var formattedData = date + " " + hour;

      const cellDate = new Date(formattedData);
      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

  function DateRangeColumnFilter({
    column: {
      filterValue = [],
      preFilteredRows,
      setFilter,
      id
    }})
  {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
      let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0)
      preFilteredRows.forEach(row => {
        const rowDate = new Date(row.values[id])
        min = rowDate <= min ? rowDate : min
        max = rowDate >= max ? rowDate : max
      })

      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <div>
        <input
          min={min.toISOString()}
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? val.concat("T00:00:00.000Z") : undefined, old[1]])
          }}
          type="date"
          value={filterValue[0]?.slice(0, -14) || ''}
        />
        <br></br>
        <input
          max={max.toISOString()}
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? val.concat("T23:59:59.999Z") : undefined])
          }}
          type="date"
          value={filterValue[1]?.slice(0, -14) || ''}
        />
      </div>
    )
  }

export const columnsDataDevelopment = [
  {
    Header: "Timestamp (UTC)",
    accessor: "timestamp",
    Filter: DateRangeColumnFilter,
    filter: dateBetweenFilterFn
  },
  {
    Header: "Wallet Address",
    accessor: "walletAddress",
    Filter: TextFilter
  },
  {
    Header: "Country",
    accessor: "country",
    Filter: SelectColumnFilter,
  },
  {
    Header: "Browser",
    accessor: "browserName",
    Filter: SelectColumnFilter,
  },
  {
    Header: "Wallet Name",
    accessor: "walletName",
    Filter: SelectColumnFilter
  },
  {
    Header: "IP",
    accessor: "userIP",
  },
  {
    Header: "Device",
    accessor: "device",
    Filter: SelectColumnFilter,
    filter: 'exact',
  },
];
