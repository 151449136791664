// Chakra imports
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, signInPageLogo } = props;
  // Chakra color mode
  return (
    <Flex
      position="relative"
      h="80vh"
      align="center"
      justify="center"
    >
      <Flex
        h="max-content"
        w="100%"
        maxW={{ md: "66%", lg: "700px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent="center" // Center the content horizontally
        direction="column"
      >
        {children}
        <Box
          display={{ base: "none", md: "block" }}
          h="96%"
          minH="50vh"
          w={{ lg: "20vw", "2xl": "6.5vw" }}
          position="absolute"
          right="0px"
        >
        <img src={signInPageLogo} style={{ width: "100px", height: "100px" }} alt="SignInIcon" />
        </Box>
      </Flex>
    </Flex>
  );
}

// PROPS
AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
