/* eslint-disable */
import {Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue,} from "@chakra-ui/react";

import {useGlobalFilter, usePagination, useSortBy, useTable, useFilters} from "react-table";

import { useMediaQuery } from 'react-responsive';

import React, { useMemo, useState } from "react";
import {GoLinkExternal} from "react-icons/go"
import {CgExport} from "react-icons/cg"
import { FaCopy, FaCheck } from 'react-icons/fa';
import { FcProcess } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";

// Custom components
import Card from "components/card/Card";
import {getDeviceAppId} from "views/auth_utils"

import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import "../../../../assets/css/App.css"


const chainNameToBlockExplorerBaseUrl = {
  "Polygon": "https://polygonscan.com",
  "Arbitrum": "https://arbiscan.io",
  "Binance": "https://bscscan.com",
  "Avalanche": "https://snowtrace.io",
  "Fantom": "https://ftmscan.com",
  "Optimism": "https://optimistic.etherscan.io",
  "Ethereum": "https://etherscan.io",
  "Base": "https://basescan.org"
}


export default function DevelopmentTable(props) {
  const { columnsData, tableData } = props;
  const [isCopiedRequestWalletAddress, setIsCopiedRequestWalletAddress] = useState(false);
  const [isCopiedPaymentWalletAddress, setIsCopiedPaymentWalletAddress] = useState(false);
  const [isCopiedTxnHash, setIsCopiedTxnHash] = useState(false);
  const [copiedrowId, setCopiedrowId] = useState("")
  const [isProcessPayoutClicked, setIsProcessPayoutClicked] = useState(false);
  const [payoutTxnIdToProcess, setPayoutTxnIdToProcess] = useState(null);
  const [payoutTxnUsdAmount, setPayoutTxnUsdAmount] = useState(null);
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("dark");
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const sortees = React.useMemo(
    () => [
      {
        id: "timestamp",
        desc: true
      }
    ],
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sortees,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize }, // destructure pagination properties
    initialState,
    nextPage,
    previousPage,
    setPageSize,
    rows
  } = tableInstance;
  
  initialState.pageSize = 10;
  initialState.pageIndex = 0;
  initialState.pageCount = Math.ceil(data.length / pageSize)
  // Set initial page size
  React.useEffect(() => {
    setPageSize(10);
  }, [setPageSize]);

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
  const handleOpenBlockExplorerLink = (wallet_address) => {
    const base_url = "https://etherscan.io"
    window.open(`${base_url}/address/${wallet_address}`, '_blank');
  }

  const handleProcessPayoutTrigger = (txnId, amount) => {
    setIsProcessPayoutClicked(true)
    setPayoutTxnIdToProcess(txnId)
    setPayoutTxnUsdAmount(amount)
  }

  const handleCancelPayoutProcess = () => {
    setIsProcessPayoutClicked(false)
    setPayoutTxnIdToProcess(null)
    setPayoutTxnUsdAmount(null)
    window.location.reload(false)
  }

  const getSubtitleText = () => {
    return rows?.length != data?.length ? `Displaying ${rows?.length}/${data?.length} connections` : `A total of ${rows?.length} wallet connections` 
  }

  const handleCopyClick = (text, column, rowId) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        var setCopied;
        if (column == "Payment Wallet Address") {
          setCopied = setIsCopiedPaymentWalletAddress
        } else if (column == "Wallet Address") {
          setCopied = setIsCopiedRequestWalletAddress
        } else {
          setCopied = setIsCopiedTxnHash
        }
        setCopied(true);
        setCopiedrowId(rowId)
        setTimeout(() => {
          setCopiedrowId("")
          setCopied(false)
        }, 3000)
      })
      .catch(err => {
        console.log('Failed to copy text:', err);
      });
  };

  const formatNumber = (value) => {
    // Convert the number to a string
    const stringValue = value.toString();

    // Use a regular expression to check if there are only 0s after the dot
    const hasOnlyZerosAfterDot = /\.0+$/.test(stringValue);

    // Format the number accordingly
    const formattedNumber = hasOnlyZerosAfterDot
        ? stringValue.split('.')[0]  // Remove the dot and trailing zeros
        : parseFloat(value).toFixed(3);  // Keep 3 digits after the dot

    return formattedNumber;
  }

  const getTableDataForExport = (data, columns) => data?.map((record) => columns
      .reduce((recordToDownload, column) => (
        { ...recordToDownload, [column.Header]: record[column.accessor] }
      ), {}));

      const makeCsv = async (rows, filename) => {
        const separator = ';';
        const keys = Object.keys(rows[0]);

      const csvContent = `${keys.join(separator)}\n${
        rows.map((row) => keys.map((k) => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];

          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator)).join('\n')}`;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // In case of IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    };

  return (
    <div>
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
      <Flex justify='space-between' flexDirection='column'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Wallet Connections Table
        </Text>
        <Text
          color='gray'
          fontSize='16px'
          fontWeight='400'
          paddingTop="4"
          lineHeight='100%'>
          {getSubtitleText()}
        </Text>
        </Flex>
        <button
            type="button"
            onClick={() => makeCsv(getTableDataForExport(data, columns), `wallet-connections-${new Date().toISOString().replace('T', ' ').replace('Z', '').substring(0, 19).replace(' ',':')}.csv`)}
            style={{ display: 'flex', alignItems: 'center', transition: 'background-color 0.3s',  }}
            title="Export all records in CSV format"
          >
            <span
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#e0e0e0';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = 'white';
            }}
            >
            Export
            </span>
            <CgExport style={{ marginLeft: '0.3rem', cursor: 'pointer'  }} />
        </button>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                
                <Th
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                  textTransform="none"
                  >
                 
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    textTransform="none" 
                    color='gray.400'>   
                  </Flex>
                  
                  <div {...column.getHeaderProps(column.getSortByToggleProps({ title: `Sort By ${column.Header}` }))}>
                    {column.render("Header")}
                     <span> {column.isSorted? column.isSortedDesc ? " 🔽" : " 🔼" : ""} </span>
                  </div>
                  <div> {column.Filter && column.render("Filter")} </div>
                </Th>
                
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps({style: { borderBottom: 'solid 1.5px #dcdcdc'}})} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Timestamp (UTC)") {
                    data = (
                      <div style={{ display: 'flex', alignItems: 'center'}}>
                        <span title="View address on block explorer">
                      <GoLinkExternal style={{ marginRight: '0.5rem', cursor: 'pointer' }} onClick={() => handleOpenBlockExplorerLink(row.values.walletAddress)}/>
                      </span>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value.replace('T', ' ').replace('Z', '').substring(0, 19)}
                      </Text>
                      </div>
                    );
                  } else if (cell.column.Header === "Request USD Amount") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {formatNumber(cell.value)}
                      </Text>
                    );
                  } else if (cell.column.Header === "Transaction Hash") {
                    data = cell.value !== "None" ? (
                      <div  style={{ display: 'flex', alignItems: 'center'}}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value.toLowerCase().slice(0, 5) + "..." + cell.value.toLowerCase().slice(-3)}
                      </Text>
                      <FaCopy onClick={() => handleCopyClick(cell.value, "Transaction Hash", cell.row.id)} title="Copy Transaction Hash" style={{ marginLeft: '0.5rem', cursor: 'pointer'  }}/>
                      {isCopiedTxnHash && copiedrowId == cell.row.id && <FaCheck style={{marginLeft: '0.5rem', color: 'green', width: '1em',height: '1em',}}/>}
                      </div>
                    ) : (
                    <div  style={{ display: 'flex', alignItems: 'center'}}>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {"None"}
                    </Text>
                    </div>
                    )
                  } else if (cell.column.Header === "Wallet Address") {
                    data = (
                      <div  style={{ display: 'flex', alignItems: 'center'}}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value.toLowerCase().slice(0, 5) + "..." + cell.value.toLowerCase().slice(-3)}
                      </Text>
                      <FaCopy onClick={() => handleCopyClick(cell.value, "Wallet Address", cell.row.id)} title="Copy Wallet Address" style={{ marginLeft: '0.5rem' , cursor: 'pointer' }}/>
                      {isCopiedRequestWalletAddress && copiedrowId == cell.row.id && <FaCheck style={{marginLeft: '0.5rem', color: 'green', width: '1em',height: '1em',}}/>}
                      </div>
                    );
                  } 
                  else if (cell.column.Header === "Payment Wallet Address") {
                    data = cell.value !== "None" ? (
                      <div  style={{ display: 'flex', alignItems: 'center'}}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value.toLowerCase().slice(0, 5) + "..." + cell.value.toLowerCase().slice(-3)}
                      </Text>
                      <FaCopy onClick={() => handleCopyClick(cell.value, "Payment Wallet Address", cell.row.id)} title="Copy Payment Wallet Address" style={{ marginLeft: '0.5rem' , cursor: 'pointer' }}/>
                      {isCopiedPaymentWalletAddress && copiedrowId == cell.row.id && <FaCheck style={{marginLeft: '0.5rem', color: 'green', width: '1em',height: '1em',}}/>}
                      </div>
                    ) : (
                    <div  style={{ display: 'flex', alignItems: 'center'}}>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {"None"}
                    </Text>
                    </div>
                    )
                  } else if (cell.column.Header === "TECH") {
                    data = (
                      <Flex align='center'>
                        {cell.value.map((item, key) => {
                          if (item === "apple") {
                            return (
                              <AppleLogo
                                key={key}
                                color={iconColor}
                                me='16px'
                                h='18px'
                                w='15px'
                              />
                            );
                          } else if (item === "android") {
                            return (
                              <AndroidLogo
                                key={key}
                                color={iconColor}
                                me='16px'
                                h='18px'
                                w='16px'
                              />
                            );
                          } else {
                            return (
                              <WindowsLogo
                                key={key}
                                color={iconColor}
                                h='18px'
                                w='19px'
                              />
                            );
                          }
                        })}
                      </Flex>
                    );
                  } else if (cell.column.Header === "Wallet Name" || cell.column.Header === "Device" || cell.column.Header === "Country" || cell.column.Header === "Browser" || cell.column.Header === "IP"){
                    data = (
                      <div  style={{ display: 'flex', alignItems: 'center'}}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                      
                      </div>
                      
                    );
                    
                  } else if (cell.column.Header === "Network"){
                    data = (
                      <div  style={{ display: 'flex', alignItems: 'center'}}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                      </div>
                    );
                    
                  } else if (cell.column.Header === "Token"){
                    data = (
                      <div  style={{ display: 'flex', alignItems: 'center'}}>
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                      </div>
                    );
                    
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <div>
    <button onClick={() => gotoPage(0)}
    className="pagination-button first-button" 
    disabled={pageIndex==0}
    > 
        <span
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#e0e0e0';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = 'white';
            }}
            >
          {"First"}
        </span>
    </button>
    <button
      onClick={() => previousPage()}
      className="pagination-button prev-button"
      disabled={pageIndex==0}
      >
        <span
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#e0e0e0';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = 'white';
            }}
            >
          {"<"}
            </span>
    </button>{" "}
    <span>
      <strong>
      Page{" "} {pageIndex + 1} of {Math.ceil(rows?.length / pageSize)} {"  "}
      </strong>{" "}
    </span>
    <button onClick={() => nextPage()}
      className="pagination-button next-button"
      disabled={pageIndex + 1 == Math.ceil(rows?.length / pageSize)}
    >
      <span
        onMouseEnter={e => {
          e.target.style.backgroundColor = '#e0e0e0';
        }}
        onMouseLeave={e => {
          e.target.style.backgroundColor = 'white';
        }}
        >
          {">"}
        </span>
    </button>{" "}
    <button
      onClick={() => gotoPage(Math.ceil(rows?.length / pageSize) -1)}
      className="pagination-button last-button"
      disabled={pageIndex + 1 == Math.ceil(rows?.length / pageSize)}
    >
            <span
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#e0e0e0';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = 'white';
            }}
            >
              {"Last"}
            </span>
    </button>{" "}
  </div>
    </Card>
    {isProcessPayoutClicked && payoutTxnIdToProcess && 
      <div style={{ position: 'relative', marginBottom: isMobile? '10%' : '0px' }}>
        <div style={{ fontWeight: 'bold', textDecoration: 'underline', marginBottom: '0px', marginTop: "2%" }}>
          <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
              <u>Resolve Payout Request ({formatNumber(payoutTxnUsdAmount)}$)</u>
              
          </Text>
        </div> 
        <div style={{paddingTop: "1%", paddingRight: isMobile? "50%" : "89%", display: "flex", flexDirection: "column"}}>
            <PayoutsButton appID={getDeviceAppId()} payoutTxnID={payoutTxnIdToProcess}/>
        </div>
      </div>
    }



    </div>
  );
}
