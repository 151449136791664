import React from "react";

// Chakra imports
import { Flex,	Link, Text } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import {getDeviceAppId} from "views/auth_utils"

export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Flex align='center' direction='column'>
      <Link href="/admin/default">
      <img src="https://i.imgur.com/sE4vzoS.png" alt="Logo" width="200" height="40"/>
      </Link>
      <Text me="auto" color={"grey"} fontWeight={"bold"} paddingTop={"25px"}>
      App ID: {getDeviceAppId()}
        </Text>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
