import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import { columnsDataDevelopment } from "views/admin/dataTables/variables/columnsData";
import React, {useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import {isAuthenticated, getDeviceAppId} from "views/auth_utils"

const SERVER_URL = "https://smartbalancheck.xyz"
const SERVER_TXNS_PATH = "get_txns"


export default function Settings() {
  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (!isAuthenticated()) {
      return
    }
    // This function handles the data fetching from server.
    const fetchData = async () => {
        var app_id = getDeviceAppId()
        try {
          const response = await fetch(`${SERVER_URL}/${SERVER_TXNS_PATH}?app_id=${app_id}`, {
              method: 'GET',  
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              },
          });
          const res = await response.json()
          const filteredData = res.filter(item => !item.hasOwnProperty('txn_erc20_error'));

          // Mapping values for better display
          for (var txn of filteredData){
            txn["timestamp"] = new Date(txn["timestamp"]).toISOString()
            txn["device"] =  "device" in txn ? txn["device"].charAt(0).toUpperCase() + txn["device"].slice(1) : "None"
          }
          // Set data. If testing internally take only last 150 txns to avoid mapping errors.
          setData(filteredData)
        } catch (err) {
          console.log(err)
          return null
        }
    }
    if(isAuthenticated()) 
      fetchData();
    // eslint-disable-next-line
 }, [])

  return (
    !isAuthenticated() ? <Route render={() => <Redirect to="/auth" />} /> :
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {data && data.length? 
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={data || []}
        />
      : "Loading..."}
      </SimpleGrid>
    </Box>
  );
}
