import React from 'react';
import {Button,Flex,Icon,Link,Menu,MenuButton,MenuList,Text,useColorModeValue} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';

import { SidebarResponsive } from 'components/sidebar/Sidebar';

import {getDeviceAppId} from "views/auth_utils"
import routes from 'routes.js';

export default function HeaderLinks(props) {
	const { secondary } = props;
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="30px"
			boxShadow={shadow}>
			<SidebarResponsive routes={routes} />

			<Menu>
				{/* <MenuButton p="0px">
					<Icon mt="6px" as={MdInfoOutline} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton> */}
				<MenuList
					boxShadow={shadow}
					p="20px"
					me={{ base: '30px', md: 'unset' }}
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					minW={{ base: 'unset' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex flexDirection="column">
							<Text me="auto" color={"#616161"} fontWeight={"bold"} paddingBottom={"10px"} >
							App ID: {getDeviceAppId()}
							</Text>
						{/* <Link w="100%" target="_blank" href="https://google.com">
							<Button
								w="100%"
								h="44px"
								mb="10px"
								border="1px solid"
								bg="transparent"
								borderColor={borderButton}>
								Learn More
							</Button>
						</Link>
						<Link w="100%" target="_blank" href="https://google.com">
							<Button
								w="100%"
								h="44px"
								mb="10px"
								border="1px solid"
								bg="transparent"
								borderColor={borderButton}>
								Documentation
							</Button>
						</Link>
						<Text me="auto" color={"grey"} fontWeight={"bold"} paddingTop={"10px"}>
							info@name-services.io
						</Text> */}
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
