import { Box, Flex, Link, HStack, Text, Stack, Icon } from "@chakra-ui/react";
import {MdLock} from "react-icons/md";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import {logout} from "views/auth_utils"
import { Redirect, Route } from "react-router-dom";


function SidebarContent(props) {
  const { routes } = props;

  const [logoutClicked, setLogoutClicked] = React.useState(false)
  // Handles logout functionality
  const handleLogout = () => {
    logout() // Removes local storage login variables
    setLogoutClicked(true) // Sets the state in order to redirect to /auth path
  }

  // SIDEBAR
  return (
    logoutClicked ? <Route render={() => <Redirect to="/auth" />} /> :
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>

        {/* Logout menu item */}
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <HStack
            spacing={"25px"}
            py="50px"
            ps="10px"
          >
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                color={"grey"}
                me="18px"
              >
                <Icon as={MdLock} width='20px' height='18px' color='inherit' />
              </Box>
              <Text
                me="auto"
                color={"grey"}
                fontWeight={"normal"}
                paddingTop={"-10px"}
              >
                <Link onClick={handleLogout}>
                Logout
                </Link>
              </Text>
            </Flex>
          </HStack>
        </Box>
      </Stack>

      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        mb='40px'
        borderRadius='30px'>
      </Box>
    </Flex>
  );
}

export default SidebarContent;
