import React from "react";

import { Icon } from "@chakra-ui/react";
import {MdOutlineMonetizationOn, MdHome, MdLock, MdStackedBarChart, MdWallet} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import PayoutsTable from "views/admin/payouts";
import DataTables from "views/admin/dataTables";

// Auth Imports
import SignInCentered from "views/auth/signIn";

import {getDeviceAppId} from "views/auth_utils"

const APP_IDS_WITH_PAYOUTS_ENABLED = ['someid']

// const payouts_route =   {
//     name: "Payouts Table",
//     layout: "/admin",
//     path: "/payouts",
//     icon: <Icon as={MdStackedBarChart} width='20px' height='20px' color='inherit' />,
//     component: PayoutsTable,
// }

var routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Transactions",
    layout: "/admin",
    icon: <Icon as={MdOutlineMonetizationOn} width='20px' height='20px' color='inherit' />,
    path: "/pay-ins",
    component: DataTables,
  },
  {
    name: "Wallet Connections",
    layout: "/admin",
    icon: <Icon as={MdWallet} width='20px' height='20px' color='inherit' />,
    path: "/wallet-connections",
    component: PayoutsTable,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
];

// if (APP_IDS_WITH_PAYOUTS_ENABLED.includes(getDeviceAppId())) routes.push(payouts_route) 

export default routes;
