const MINUTE_IN_SECONDS = 60
const AUTHENTICATION_TIMEOUT_IN_SECONDS = MINUTE_IN_SECONDS * 30;
const LAST_SUCCESSFUL_LOGIN_TIMESTAMP_STORAGE_VAR = "lastSuccessfulLoginWithCredentialsTimestamp"
const APP_ID_STORAGE_VAR = "appId"


const getUnixTimeUtc = (dateString = new Date()) => Math.round(new Date(dateString).getTime() / 1000)


// Checks if the device is authenticated using the defined threshold
export default function isAuthenticated() {
    // Take last successfull login from local storage
    const lastSuccessfulLoginWithCredentialsTimestamp = JSON.parse(
        window.localStorage.getItem(LAST_SUCCESSFUL_LOGIN_TIMESTAMP_STORAGE_VAR)
    )
    const appId = JSON.parse(
        window.localStorage.getItem(APP_ID_STORAGE_VAR)
    )
    // If not found in local storage, it means that the current device never authenticated successfully / logged out
    if (!lastSuccessfulLoginWithCredentialsTimestamp || !appId) {
        return false
    }
    // If found in local storage, check what is the time difference in seconds between now to last successfull login (with credentials)
    const currentTime = getUnixTimeUtc();
    const timeDifference = currentTime - lastSuccessfulLoginWithCredentialsTimestamp;
    return timeDifference < AUTHENTICATION_TIMEOUT_IN_SECONDS;
}


// Verifies credentials input by the user against our DB details
async function verifyCredentialsInput(appId, password) {
    // Define the mapping of app IDs to passwords
    const credentials = {
        "the-smart-contract": "8#Yk2D$pfQ",
        "base-user-config": "Lm&7zP*0nX",
        "the-token-trail": "Xz&5pN@1mL"
    };

    if (!appId || !password) {
        return { success: false, reason: "Application ID and Password must be non-empty" };
    }

    // Check if the provided appId exists in the credentials mapping
    if (!(appId in credentials)) {
        return { success: false, reason: "Application ID does not exist" };
    }

    // Check if the provided password matches the stored password
    if (credentials[appId] !== password) {
        return { success: false, reason: "Incorrect Password" };
    }

    // Return success if both appId and password match
    return { success: true, reason: "Success" };
}



// Logout the connected device, which includes removing the login variables from local storage
function logout() {
    localStorage.removeItem(APP_ID_STORAGE_VAR);
    localStorage.removeItem(LAST_SUCCESSFUL_LOGIN_TIMESTAMP_STORAGE_VAR)
}


// Sets the relevant variables after a succesful login; timestamp of the login and the app id used
function writeSuccessfullAuthentication(appId) {
    window.localStorage.setItem(
        APP_ID_STORAGE_VAR,
        JSON.stringify(appId)
    )
    window.localStorage.setItem(
        LAST_SUCCESSFUL_LOGIN_TIMESTAMP_STORAGE_VAR,
        JSON.stringify(getUnixTimeUtc())
    )
}

// Returns the app id written to local storage on the last succesful login
function getDeviceAppId() {
    const appId = JSON.parse(
        window.localStorage.getItem(APP_ID_STORAGE_VAR)
    )
    return !appId ? "" : appId 
}

export {isAuthenticated, writeSuccessfullAuthentication, verifyCredentialsInput, getDeviceAppId, logout}