/* eslint-disable */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {Box,Button,Flex,FormControl,FormLabel,Heading,Icon,Input,InputGroup,InputRightElement,Text,useColorModeValue} from "@chakra-ui/react";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BiErrorCircle } from "react-icons/bi"
import { RiEyeCloseLine } from "react-icons/ri";
import {ImSpinner8} from "react-icons/im"

import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";

import "./spinner.css";
import {isAuthenticated, writeSuccessfullAuthentication, verifyCredentialsInput} from "views/auth_utils"


function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [appId, setAppId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [showAuthErrorMessage, setShowAuthErrorMessage] = useState(false);
  const [showSigninLoader, setShowSigninLoader] = useState(false)


  const timer = React.useRef();

  const history = useHistory();

  const handleSignIn = async (appId, password) => {
    if (isAuthenticated()){ // If device already authenticated before time threshold, no need to check password
      history.push("/admin"); // Redirect to '/admin' after successful authentication
    } else { // Perform authentication logic here
      setShowSigninLoader(true)
      const verificationResults = await verifyCredentialsInput(appId, password)
      if(verificationResults["success"]){ // Authentication success
        writeSuccessfullAuthentication(appId)
        history.push("/admin"); // Redirect to '/admin' after successful authentication
        setShowSigninLoader(false)
      } else { // Authentication failure, show error message
        setShowSigninLoader(false)
        setShowAuthErrorMessage(true)
        setAuthErrorMessage(verificationResults["reason"])
        timer.current = window.setTimeout(() => { // Present error message
          setShowAuthErrorMessage(false)
          setAuthErrorMessage("")
        }, 1000 * 5);
      }
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await handleSignIn(appId, password);
    }
  };

  return (
    <DefaultAuth signInPageLogo={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='25px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            paddingTop={'10px'}
            fontSize='md'>
            Enter your application ID and password to sign in
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <Flex align='center' mb='25px'>
            <HSeparator />
          </Flex>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Application ID<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='Your Application ID'
              mb='24px'
              fontWeight='500'
              size='lg'
              disabled={showSigninLoader || showAuthErrorMessage}
              value={appId}
              onChange={(e) => setAppId(e.target.value)}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Your Password'
                mb='24px'
                size='lg'
                disabled={showSigninLoader || showAuthErrorMessage}
                type={showPassword ? "text" : "password"}
                variant='auth'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={async (e) => {await handleKeyDown(e)}} // Trigger signin on "Enter" key press
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </InputRightElement>
            </InputGroup>
            {!showSigninLoader && !showAuthErrorMessage && 
                      <Button fontSize='sm' variant='brand' fontWeight='500' w='100%' h='50' mb='24px' 
                            onClick={async () => {await handleSignIn(appId, password);} }>
                        Sign In
                      </Button>}
            {showSigninLoader && <div><ImSpinner8 className="loading-spinner"/></div>}
            {showAuthErrorMessage && <div>
                            <Text style={{color:"red",width:'100%',height:'30px',textAlign:'center'}}>{authErrorMessage}</Text>
                            <BiErrorCircle style={{color: "red",width:'100%',height:'50px'}}/>  </div>}
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
