import { Flex, Text, Select, useColorModeValue } from "@chakra-ui/react";
import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";

import Card from "components/card/Card.js";

import { useMediaQuery } from 'react-responsive';
import {getLegendLabels} from '../utils/chartsUtils'

//TODO: Select color palletes
//TODO: Fix pie size change

export default function DonutCard(props) {
  const { data, ...rest } = props;
  const [txnsInterval, setTxnsInterval] = useState("all")

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed
  // Calculate the size of the pie chart and legend based on the number of unique elements
  const pieChartHeight = isMobile ? '230px' : '370px';
  const donutOptions = {
    chart: {
      type: 'donut',
    },
    colors: ['#4e79a7', '#85c1ed'],
    labels: ['Desktop', 'Mobile'],
    dataLabels: {
        enabled: false,
      },
    legend: {
        show: true,
        position: 'bottom',
        customLegendItems: data && txnsInterval in data ? getLegendLabels(['Desktop', 'Mobile'], data[txnsInterval]["counts"], data[txnsInterval]["counts"].reduce((a, b) => a + b, 0)) : [],
        fontSize: isMobile ? '10px' : '14px'
    
      },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
        },

      }
    }]
  }
  return (
    <Card p='20px' align='center' direction='column' w='100%' h="100%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
            {data ? `Transactions by Device (${data[txnsInterval]?.["counts"].reduce((a, b) => a + b, 0)} Total)` : ""}
        </Text>
        <Select
          fontSize='sm'
          variant='subtle'
          defaultValue='all'
          width='unset'
          fontWeight='700'
          onChange={(ev) => setTxnsInterval(ev?.target?.value)}
          >
          <option value='all'>All</option>
          <option value='monthly'>Monthly</option>
          <option value='weekly'>Weekly</option>
          <option value='daily'>Daily</option>
        </Select>
      </Flex>
      {/* React Apex Charts Demos: https://apexcharts.com/react-chart-demos/ */}
      {data && txnsInterval in data ?
        data[txnsInterval]?.["counts"]?.length ?
      <ReactApexChart
              options={donutOptions}
              series={data[txnsInterval]["counts"]}
              type="donut"
              width="100%"
              height={pieChartHeight}
            /> : <div style={{paddingTop: isMobile? "10px" : "100px"}} >No {txnsInterval} transactions found</div>
            : "Loading..."}
       
    </Card>
  );
}
