const hours_intervals_categories = ["00:00-03:00", "03:00-06:00", "06:00-09:00", "09:00-12:00", "12:00-15:00", "15:00-18:00", "18:00-21:00", "21:00-00:00"]


export const transactionsCountDisributionOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "15px", //todo: add is mobile
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
    x: {
      formatter: function (_, { series, seriesIndex, dataPointIndex, w }) {
        return `Time Interval: ${hours_intervals_categories[dataPointIndex]}`;
      },
    },
  },
  xaxis: {
    categories: hours_intervals_categories,
    show: false,
    labels: {
      show: true,
      style: {
        colors: '#4e79a7',// '#85c1ed',
        fontSize: "16px", //todo: add is mobile
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#4e79a7",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#85c1ed",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#4e79a7",
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
};



export const transactionsValueDisributionOptions = (datas, categories) => {

  // Calculate maximum and minimum values
  let maxValue = Number.MIN_SAFE_INTEGER;
  let minValue = Number.MAX_SAFE_INTEGER;

  datas.forEach((item) => {
    item.data.forEach((value, index) => {
      const sum = datas.reduce((acc, d) => acc + (d.data[index] ? d.data[index] : 0), 0);
      maxValue = Math.max(maxValue, sum);
      minValue = Math.min(minValue, sum);
    });
  });

  return {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
    x: {
      formatter: function (_, { series, seriesIndex, dataPointIndex, w }) {
        var totalSum = String(series.reduce((acc,curr) => acc + (curr[dataPointIndex] ? curr[dataPointIndex] : 0),0))
        totalSum = totalSum.substring(0, totalSum.indexOf(".") + 4)
        return `Total revenue for ${categories[dataPointIndex]} interval: $${totalSum}`
      },
    },
  },
  xaxis: {
    categories: categories,
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    min: 0,
    max: maxValue * 1.05,
    show: false,
    color: "black",
    labels: {
      show: false,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },
  grid: {
    borderColor: "rgba(163, 174, 208, 0.3)",
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#0000b3", "#0060ff", "#009fff", "#00ffff"],
  },
  legend: {
    show: false,
  },
  colors: ["#0000b3", "#0060ff", "#009fff", "#00ffff"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
}
};


// Returns labels with percentage for pie/donut visualizations
export const getLegendLabels = (data, counts, total) => 
  data.map((dataPoint, index) => 
      `${dataPoint} (${Math.round((counts[index]/total) * 100)}%)`
  )


//https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
const colorPalette = ['#4e79a7', '#6a9fd6', '#85c1ed', '#a5d9ff', '#bcbddc', '#dcb4e2', '#f3b1e9', '#ffb6ce', '#ffcbdb', '#fed6e3', '#ffd9c2', '#ffe5d1', '#fff3dd', '#fff9eb', '#ffffff'];

export const pieChartOptions = (data, counts, isMobile) => {
  // const colors = getMultipleRandom(colorPalletes, data.length)
  return {
  labels: data,
  colors: colorPalette,
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
    customLegendItems: getLegendLabels(data, counts, counts.reduce((a, b) => a + b, 0)),
    fontSize: isMobile ? '10px' : '14px'

  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    total: {
      enabled: false
    },
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: colorPalette
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};
}







// Total Spent Default

// export const lineChartDataTotalSpent = [
//   {
//     name: "Revenue",
//     data: [50, 64, 48, 66, 49, 68],
//   },
//   {
//     name: "Profit",
//     data: [30, 40, 24, 46, 20, 46],
//   },
// ];

// export const lineChartOptionsTotalSpent = {
//   chart: {
//     toolbar: {
//       show: false,
//     },
//     dropShadow: {
//       enabled: true,
//       top: 13,
//       left: 0,
//       blur: 10,
//       opacity: 0.1,
//       color: "#4318FF",
//     },
//   },
//   colors: ["#4318FF", "#39B8FF"],
//   markers: {
//     size: 0,
//     colors: "white",
//     strokeColors: "#7551FF",
//     strokeWidth: 3,
//     strokeOpacity: 0.9,
//     strokeDashArray: 0,
//     fillOpacity: 1,
//     discrete: [],
//     shape: "circle",
//     radius: 2,
//     offsetX: 0,
//     offsetY: 0,
//     showNullDataPoints: true,
//   },
//   tooltip: {
//     theme: "dark",
//   },
//   dataLabels: {
//     enabled: false,
//   },
//   stroke: {
//     curve: "smooth",
//     type: "line",
//   },
//   xaxis: {
//     type: "numeric",
//     categories: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"],
//     labels: {
//       style: {
//         colors: "#A3AED0",
//         fontSize: "12px",
//         fontWeight: "500",
//       },
//     },
//     axisBorder: {
//       show: false,
//     },
//     axisTicks: {
//       show: false,
//     },
//   },
//   yaxis: {
//     show: false,
//   },
//   legend: {
//     show: false,
//   },
//   grid: {
//     show: false,
//     column: {
//       color: ["#7551FF", "#39B8FF"],
//       opacity: 0.5,
//     },
//   },
//   color: ["#7551FF", "#39B8FF"],
// };
