import React, {useState} from "react";

// Chakra imports
import { Box, Flex, Select,Text, useColorModeValue } from "@chakra-ui/react";
import Chart from "react-apexcharts";

// Custom components
import Card from "components/card/Card.js";
import {transactionsCountDisributionOptions} from "../utils/chartsUtils";
import { useMediaQuery } from 'react-responsive';


export default function TransactionsCountDisribution(props) {
  const { data, ...rest } = props;
  const [txnsInterval, setTxnsInterval] = useState("all")
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed


  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex justify='space-between' align='start' px='10px' pt='5px'>
        <Flex flexDirection='column' align='start' me='20px'>
          <Flex w='100%'>
          <Text color={textColor} fontSize='xl' fontWeight='700' mt='4px'>
              
              {data && txnsInterval in data ? ` Transactions Count Distribution Over Time (UTC) (${data[txnsInterval]?.reduce((a, b) => a + b, 0)} Total)` : ""}
            </Text>
          </Flex>
        </Flex>
        <Select
          fontSize='sm'
          variant='subtle'
          defaultValue='all'
          width='unset'
          fontWeight='700'
          onChange={(ev) => setTxnsInterval(ev?.target?.value)}
          >
          <option value='all'>All</option>
          <option value='monthly'>Monthly</option>
          <option value='weekly'>Weekly</option>
          <option value='daily'>Daily</option>
        </Select>
      </Flex>
      <Box h='240px' mt='auto'>
        {data && txnsInterval in data?
          data[txnsInterval]?.reduce((a, b) => a + b, 0) > 0 ?
          <Chart
              options={transactionsCountDisributionOptions} 
              series={[{name: "Transactions Count", data: data[txnsInterval]}]}
              type='bar'
              width='100%'
              height='100%'
            />  : <div style={{paddingTop: isMobile? "100px" : "100px"}} >No {txnsInterval} transactions found</div>
          : "Loading..."}
      </Box>
    </Card>
  );
}
