const txnIntervals = ["all", "monthly", "daily", "weekly"]

// Seperate transaction to time intervals
/* eslint-disable no-loop-func */
function seperateTxnsToIntervals(txns){
    // Get the current date
    const currentDate = new Date();
  
    // Define the duration in milliseconds for last day, last week, and last month
    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay;
  
    // Initialize lists
    const lastDayList = [];
    const lastWeekList = [];
    const lastMonthList = [];
    txns.forEach((obj) => {
      const timestamp = new Date(obj.timestamp);
  
      // Calculate the time difference in milliseconds between the current date and the object's timestamp
      const timeDifference = currentDate - timestamp;
      // Use a switch statement to determine which list to add the object to
      if (timeDifference <= oneDay) lastDayList.push(obj);
      if (timeDifference <= oneWeek) lastWeekList.push(obj);
      if (timeDifference <= oneMonth) lastMonthList.push(obj);
    });
  
    return {
            "all": txns,
            "daily": lastDayList,
            "monthly": lastMonthList,
            "weekly": lastWeekList
          }
}

function mapNumericalStats(txns) {
    var dataTypeObj = {};
    dataTypeObj["totalRevenue"] = {}
    dataTypeObj["avgRevenue"] = {};
    dataTypeObj["uniquePayers"] = {};
    dataTypeObj["totalTransactions"] = {};
    txnIntervals.forEach((interval) => {
        dataTypeObj["totalRevenue"][interval] = ""

        var intervalLength = txns[interval]?.length
        dataTypeObj["totalTransactions"][interval] = intervalLength


        dataTypeObj["avgRevenue"][interval] = ""

        var intervalPayers = txns[interval].map(txn => txn["sender_address"])
        var uniqueIntervalPayers = [...new Set(intervalPayers)];
        dataTypeObj["uniquePayers"][interval] = uniqueIntervalPayers?.length
    })
    return dataTypeObj
}


function mapPieStats(txns, propertyName, displayName) {
    var dataTypeObj = {};
    dataTypeObj["displayName"] = displayName;
    txnIntervals.forEach((interval) => {
        var intervalTxns = txns[interval].map(tx => tx[propertyName])
        var uniqueElements = [...new Set(intervalTxns)];
        var counts = uniqueElements.map((element) => intervalTxns.filter((item) => item === element).length);
        dataTypeObj[interval] = {"uniqueElements": uniqueElements, "counts": counts};
    })
    return dataTypeObj
}
  

function mapDeviceDateForDisplay(txns) {
    var dataTypeObj = {};
    const uniqueElements = ['desktop', 'mobile'];
    txnIntervals.forEach((interval) => {
        var intervalTxns = txns[interval].map(tx => tx["device"])
        var counts = uniqueElements.map((element) => intervalTxns.filter((item) => item === element).length);
        var mapped_counts = counts[0] === 0 && counts[1] === 0 ? [] : counts
        dataTypeObj[interval] = {"uniqueElements": uniqueElements, "counts": mapped_counts};
    })
    return dataTypeObj
}



// Consumption Users Reports
function calculateHistogramBins(min, max, numBins) {
    const binSize = (max - min) / numBins;
    const bins = [];
  
    for (let i = 0; i < numBins; i++) {
      const start = min + (binSize * i);
      const end = min + (binSize * (i + 1));
      bins.push({ start, end });
    }
  
    return bins;
}

function getTransactionsValueDistributionData(txns) {
    const HOURS_INTERVALS = [
        {start: 0, end:4},
        {start: 4, end:8},
        {start: 8, end:12},
        {start: 12, end:16},
        {start: 16, end:20},
        {start: 20, end:24}
    ];

    const NUMBER_OF_BINS = 4;

    var finalList = {}
    var accumulator = [];

    for (var selectInterval of txnIntervals) {

            // Create an object to hold the eight lists

            var selectIntervalTxns = txns[selectInterval]
            const txnsUsdValues = selectIntervalTxns.map(tx => parseFloat(tx["txnUsdPrice"])).filter(txnUsdVal => txnUsdVal && txnUsdVal !== undefined && txnUsdVal !== null)
            const minValue = Math.min(...txnsUsdValues)
            const maxValue = Math.max(...txnsUsdValues)
            const bins = calculateHistogramBins(minValue, maxValue, NUMBER_OF_BINS)
            // eslint-disable-next-line no-loop-func
            HOURS_INTERVALS.forEach((inter) => {
                bins.forEach((_bin, index) => {
                    var filtered = selectIntervalTxns.filter(tx => {
                        var hour = new Date(tx.timestamp).getUTCHours();
                        var price = parseFloat(tx.txnUsdPrice)
                        return hour >= inter.start && hour < inter.end && ((price >= _bin.start && price < _bin.end) || (price === _bin.end && (index + 1) === bins.length))
                    });
                    accumulator.push({interval: selectInterval, 
                                bin_start: _bin.start, 
                                bin_end: _bin.end, 
                                hour_start: inter.start, 
                                hour_end: inter.end,
                                totalTxnsValue: filtered.map(tx => parseFloat(tx["txnUsdPrice"])).reduce((acc,curr)=> acc + (curr ? curr : 0) ,0)})
            })})


    }

    for (selectInterval of txnIntervals) {

        const interval_txns = accumulator.filter(en => en.interval === selectInterval);

        var uniqueBinsStarts =  [...new Set(interval_txns.map(tx => tx.bin_start))]
        var uniqueBinsEnds =  [...new Set(interval_txns.map(tx => tx.bin_end))]

        var uniqueHoursStarts =  [...new Set(interval_txns.map(tx => tx.hour_start))]
        var uniqueHoursEnds =  [...new Set(interval_txns.map(tx => tx.hour_end))]

        var bins = []
        uniqueBinsStarts.forEach((bin_start, index) => {
            var bin_end = uniqueBinsEnds[index];
            bins.push({start: bin_start, end: bin_end})
        });

        var hours = []
        uniqueHoursStarts.forEach((hour_start, index) => {
            var hour_end = uniqueHoursEnds[index];
            hours.push({start: hour_start, end: hour_end})
        });

        // TODO: Handle this case properly. If less than bins length transactions found. 
        if (bins.length !== NUMBER_OF_BINS){
            finalList[selectInterval] = {}
            continue
        }

        var final_data_bins_to_return = []
        for(var bin of bins){   
            var bin_data = []
            for(var hours_intervals of hours) {
                // eslint-disable-next-line no-loop-func
                var txns_in_bin_and_hours = interval_txns.filter(tx => tx.bin_start === bin.start && tx.bin_end === bin.end && 
                                                                    tx.hour_end === hours_intervals.end && tx.hour_start === hours_intervals.start)
                var bins_hours_intervals_txns_sum = txns_in_bin_and_hours.length === 1 ? txns_in_bin_and_hours[0].totalTxnsValue : 0
                bin_data.push(bins_hours_intervals_txns_sum)
            }
            var sums = bin_data.map(value => {
                var value_str = String(value)
                return parseFloat(value_str.substring(0, value_str.indexOf(".") + 4))
            })
            var bin_start_str = String(bin.start)
            var bin_end_str = String(bin.end)
            final_data_bins_to_return.push({
                data: sums,
                name: `Revenue for transactions in range [$${bin_start_str.substring(0, bin_start_str.indexOf(".") + 4)}, $${bin_end_str.substring(0, bin_end_str.indexOf(".") + 4)}]`
            })
        }
        finalList[selectInterval] = {}
        finalList[selectInterval] = final_data_bins_to_return

    }
        finalList["hoursIntervals"] = HOURS_INTERVALS.map(item => `${item.start.toString().padStart(2, '0')}:00-${item.end.toString().padStart(2, '0')}:00`);
        return finalList

    }



function seperateTxntoHoursIntervals(txns) {

    var finalList = {}
    var listsByHourInterval = {};
    finalList["all"] = finalList["monthly"] = finalList["daily"] = finalList["weekly"] = listsByHourInterval
    for (var selectInterval of txnIntervals) {
            // Create an object to hold the eight lists
            listsByHourInterval = {
                "00-03": [],
                "03-06": [],
                "06-09": [],
                "09-12": [],
                "12-15": [],
                "15-18": [],
                "18-21": [],
                "21-00": [],
            };
            var selectIntervalTxns = txns[selectInterval]
            for (const obj of selectIntervalTxns) {

                // Parse timestamp into a Date object
                const timestamp = new Date(obj.timestamp);
                
                // Get the hour from the Date object
                const hour = timestamp.getUTCHours();
                
                // Determine the hour interval for that object
                let hourInterval;
                if (hour >= 0 && hour < 3) {
                    hourInterval = "00-03";
                } else if (hour >= 3 && hour < 6) {
                    hourInterval = "03-06";
                } else if (hour >= 6 && hour < 9) {
                    hourInterval = "06-09";
                } else if (hour >= 9 && hour < 12) {
                    hourInterval = "09-12";
                } else if (hour >= 12 && hour < 15) {
                    hourInterval = "12-15";
                } else if (hour >= 15 && hour < 18) {
                    hourInterval = "15-18";
                } else if (hour >= 18 && hour < 21) {
                    hourInterval = "18-21";
                } else {
                    hourInterval = "21-00";
                }
                
                // Add the object to the corresponding list
                listsByHourInterval[hourInterval].push(obj);
        }

        // eslint-disable-next-line no-loop-func
        finalList[selectInterval] = Object.keys(listsByHourInterval).map(key => {
                    var leng = listsByHourInterval[key]?.length
                    return leng && leng > 0 ? leng : 0
        })
    }    
        
    
    return finalList
}

export {getTransactionsValueDistributionData, mapDeviceDateForDisplay, seperateTxnsToIntervals, seperateTxntoHoursIntervals, mapNumericalStats, mapPieStats}

